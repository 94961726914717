import AppBar from '@mui/material/AppBar';
import { Container, Grid, useTheme } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FooterLogo from 'app/layouts/whitelabel/components/footer/FooterLogo';
import { footerConfig } from 'app/configs/footerConfig';
import { getLogo } from '../helpers/Generichelper';
import useWindowDimensions from 'utils/useWindowDimensions';
import SocialMediaIcons from '../../../shared-components/SocialMediaIcons/SocialMediaIcons';
import { getChannelSettingByKey } from '../../routes/generic/Helpers/GenericIndexHelper';
import FooterLayoutAdvanced from './FooterLayoutAdvanced';
import { useStylesFooterLayout } from '../styles/FooterStyles';
import { getNewBuyInsurancePathGeneric } from '../../routes/generic/Helpers/HomePageHelper';

const MenuRow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: baseline;
	justify-content: flex-start;
	flex: 1 1;
	@media only screen and (max-width: 425px) {
		padding: 0 1.5rem;
	}
`;

const MenuHeader = styled.div`
	font-size: 1.375rem;
	font-weight: 700;
	margin-bottom: 1rem;
	color: ${({ theme }) => theme.palette.footer.headerFontColor};
`;

const MenuItem = styled.div`
	margin: 3px 0;
	font-size: 13px;
	color: ${({ theme }) => theme.palette.footer.fontColor};
	padding-right: 1rem;
`;

const LinkText = styled.span`
	color: ${({ theme }) => theme.palette.footer.fontColor} !important;
`;

function FooterSecondLogo({ tertiaryLogo, tertiaryLogoText, tertiaryLogoTextColor }) {
	const logo = {
		marginTop: '10px',
		height: 'auto',
		width: 'auto',
		maxHeight: '100px',
		maxWidth: '120px'
	};
	const figcaption = {
		marginTop: '5px',
		fontSize: '10px',
		textAlign: 'center',
		color: tertiaryLogoTextColor ?? 'black'
	};
	const figContainer = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	};

	return (
		<figure style={figContainer}>
			{tertiaryLogo && <img style={logo} src={tertiaryLogo} alt="" />}
			<figcaption style={figcaption}>{tertiaryLogoText}</figcaption>
		</figure>
	);
}

function FooterLayout(props) {
	const { width } = useWindowDimensions();
	const classes = useStylesFooterLayout(width);
	const theme = useTheme();
	const channelSettings = useSelector(({ fuse }) => fuse.form.channelSettings);
	const companyDetailsResponse = useSelector(({ fuse }) => fuse.form.companyDetailsResponse);
	const footerItems = footerConfig({ companyDetailsResponse });
	const agentDetails = useSelector(({ fuse }) => fuse?.form?.referenceTokenDetails?.agentDetails);
	const channelProducts = useSelector(({ fuse }) => fuse.form.channelProducts);
	const referenceTokenDetails = useSelector(({ fuse }) => fuse.form.referenceTokenDetails);
	const myHostPrefix = useSelector(({ fuse }) => fuse.form.mySite.hostPrefix);
	const retryPaymentResponse = useSelector(({ fuse }) => fuse.form.retryPaymentResponse);
	const hideFooterMenuItems =
		retryPaymentResponse?.value || getChannelSettingByKey('HIDE-FOOTER-MENU-ITEMS') || 'false';
	const hideFirstFooter = getChannelSettingByKey('Footer-First-Hide') === 'true';
	const hideSecondFooter = getChannelSettingByKey('Footer-Second-Hide')
		? getChannelSettingByKey('Footer-Second-Hide') === 'true'
		: true;
	const hideThirdFooter = getChannelSettingByKey('Footer-Third-Hide')
		? getChannelSettingByKey('Footer-Third-Hide') === 'true'
		: true;
	const secondaryLogoSetting = getChannelSettingByKey('Secondary-Logo');
	const tertiaryLogoSetting = getChannelSettingByKey('Tertiary-Logo');
	const tertiaryLogoTextColor = getChannelSettingByKey('Footer-Logo-Text-Color');
	const tertiaryLogo =
		agentDetails?.agentLogoType === 3 && agentDetails?.agentLogoBlobUrl
			? agentDetails?.agentLogoBlobUrl
			: getLogo()?.tertiaryLogo;

	const tertiaryLogoText =
		agentDetails?.agentLogoType === 3 && agentDetails?.agentLogoText
			? agentDetails?.agentLogoText
			: getLogo()?.tertiaryLogoText;
	const buyInsurancePath = getNewBuyInsurancePathGeneric(channelProducts, referenceTokenDetails?.token, myHostPrefix);

	const linkStyle = {
		background: 'none',
		textDecoration: 'none',
		borderBottom: 'none'
	};

	const AppBarStyle = {
		backgroundColor: `${theme.palette.footer.background}`
	};

	const checkIsVisible = key => {
		return channelSettings?.value?.channelSettings.find(_key => _key.key === key)?.value;
	};

	const FooterMenuItem = ({ c }) => {
		return (
			<div>
				<MenuItem theme={theme}>
					<Link to={c.url || '/'}>
						<LinkText theme={theme}>{c.title}</LinkText>
					</Link>
				</MenuItem>
			</div>
		);
	};

	const renderFooter = (c, index) => {
		FooterMenuItem.propTypes = {
			c: PropTypes.object
		};

		if (c?.url?.slice(0, 6) === 'mailTo') {
			return (
				<div key={index}>
					<MenuItem theme={theme}>
						<a href={c.url} target="_blank" rel="noopener noreferrer" style={linkStyle}>
							<LinkText theme={theme}>{c.title}</LinkText>
						</a>
					</MenuItem>
				</div>
			);
		}		  
		if (c?.url?.slice(0, 1) === '+') {
			return (
				<div key={index}>
					<MenuItem theme={theme}>
						<a href={c.url} style={classes.Phone}>
							<LinkText theme={theme}>{c.title}</LinkText>
						</a>
					</MenuItem>
				</div>
			);
		}
		switch (c?.url) {
			case '/disclosure':
				return checkIsVisible('FooterMenu-Disclosure-Visible') === 'true' ? (
					<FooterMenuItem c={c} key={index} />
				) : null;

			case '/terms-and-conditions':
				return checkIsVisible('FooterMenu-Terms-And-Conditions-Visible') === 'true' ? (
					<FooterMenuItem c={c} key={index} />
				) : null;
			case '/submit-claim':
				return checkIsVisible('FooterMenu-Claim-Visible') === 'true' ? (
					<FooterMenuItem c={c} key={index} />
				) : null;
			case '/privacy-policy':
				return checkIsVisible('FooterMenu-PrivacyPolicy-Visible') === 'true' ? (
					<FooterMenuItem c={c} key={index} />
				) : null;
			case '/contact':
				return checkIsVisible('FooterMenu-Contact-Visible') === 'true' ? (
					<FooterMenuItem c={c} key={index} />
				) : null;
			case '/about':
				return checkIsVisible('FooterMenu-About-Visible') === 'true' ? (
					<FooterMenuItem c={c} key={index} />
				) : null;
			case '/faq':
				return checkIsVisible('FooterMenu-FAQ-Visible') === 'true' ? (
					<FooterMenuItem c={c} key={index} />
				) : null;
			case '/details':
				return checkIsVisible('FooterMenu-Details-Visible') === 'true' ? (
					<FooterMenuItem c={c} key={index} />
				) : null;
			case buyInsurancePath:
				return checkIsVisible('FooterMenu-Insurance-Visible') === 'true' ? (
					<FooterMenuItem c={c} key={index} />
				) : null;
			default:
				return <FooterMenuItem c={c} key={index} />;
		}
	};

	return (
		<>
			{!hideFirstFooter && (
				<AppBar
					id="fuse-footer"
					className={clsx('relative z-20 shadow-md', props.className)}
					color="default"
					sx={{ backgroundColor: theme.palette.footer.background }}
					style={AppBarStyle}
				>
					<Container>
						<Toolbar
							disableGutters
							className="container min-h-48 md:min-h-64 py-0 flex items-center overflow-x-auto"
						>
							<Grid
								container
								className="mt-16"
								direction="row"
								justifyContent="center"
								alignItems="flex-start"
							>
								{secondaryLogoSetting && (
									<Grid item xs={12} md={2} className="mb-16 md:mb-0">
										<FooterLogo />
									</Grid>
								)}
								{footerItems &&
									footerItems?.menus?.map(item => (
										<Grid className="mt-16 md:mt-0" item xs={item.xs} md={item.md} key={item.id}>
											{hideFooterMenuItems === 'false' && (
												<MenuRow>
													<MenuHeader theme={theme}>{item.title}</MenuHeader>
													{item?.menuItems?.map((c, index) => renderFooter(c, index))}
												</MenuRow>
											)}
										</Grid>
									))}
								{tertiaryLogoSetting && (
									<Grid className="flex flex-col items-center mt-16 md:mt-0" item md={3} xs={12}>
										<SocialMediaIcons />
										<FooterSecondLogo
											tertiaryLogo={tertiaryLogo}
											tertiaryLogoText={tertiaryLogoText}
											tertiaryLogoTextColor={tertiaryLogoTextColor}
										/>
									</Grid>
								)}
							</Grid>
						</Toolbar>
					</Container>

					<div className="mx-auto mb-20 mt-16" style={classes.recaptchaText}>
						This site is protected by reCAPTCHA and the Google
						<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
							{' '}
							Privacy Policy
						</a>{' '}
						and
						<a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
							{' '}
							Terms of Service
						</a>{' '}
						apply.
					</div>
				</AppBar>
			)}

			{/* Advanced Footer Layout */}
			{!hideSecondFooter || !hideThirdFooter ? (
				<div className="mt-10">
					<FooterLayoutAdvanced />
				</div>
			) : null}
		</>
	);
}

FooterSecondLogo.propTypes = {
	tertiaryLogo: PropTypes.string,
	tertiaryLogoText: PropTypes.string
};

export default memo(FooterLayout);
